export default defineNuxtPlugin(() => {
  addRouteMiddleware(
    'global-session',
    async to => {
      const { isLoggedIn, shouldRefreshAccessToken, getRefreshPromise, getUser } = useAuth();

      if (!isLoggedIn()) {
        // If user is not logged in
        if (to.path !== '/auth/login') {
          // Redirect to login page if user is trying to access a page that requires authentication
          return navigateTo('/auth/login');
        }
      } else {
        // If user is already logged in
        if (to.path === '/auth/login') {
          // Redirect to home if user is trying to access login page
          return navigateTo('/');
        } else if (shouldRefreshAccessToken()) {
          // Check if the token needs to be refreshed
          await getRefreshPromise();
        } else {
          // Get user if access token is not expired
          await getUser();
        }
      }
    },
    { global: true }
  );
});
