import type {
  LoggedUserInfo,
  LoggedUserInfoWorkspaceBranch,
  LoggedUserInfoWorkspaceCompany,
  SystemSettingKeys
} from '@openbox/shared-types/lib';

import { defineStore } from 'pinia';

type AuthStoreCompaniesBranch = {
  id: string;
  name: string;
};

export type AuthStoreCompanies = {
  name: string;
  branch: string;
  active: boolean;
  companyId?: string;
  branchId?: string;
  branches: AuthStoreCompaniesBranch[];
};

export const useAuthStore = defineStore('AuthStore', {
  state: () => ({
    user: null as LoggedUserInfo | null,
    pageBlocked: false
  }),
  actions: {
    logOut() {
      this.user = null;
    },
    setUser(user: LoggedUserInfo) {
      this.user = user;
    },
    blockPage() {
      this.pageBlocked = true;
    },
    unblockPage() {
      this.pageBlocked = false;
    }
  },
  getters: {
    isLoggedIn: (state): boolean => {
      return state.user ? Object.keys(state.user).length > 0 : false;
    },
    fullname: (state): string => {
      return state.user ? `${state.user.names} ${state.user.lastnames}` : '';
    },
    shortname: (state): string => {
      const firstName = state.user ? state.user.names.split(' ')[0] : '';
      const secondName = state.user ? state.user.names.split(' ')[1] : '';
      const lastName = state.user ? state.user.lastnames.split(' ')[0] : '';

      return state.user ? `${firstName}${secondName ? ` ${secondName[0]}.` : ''} ${lastName}` : '';
    },
    email: (state): string => {
      return state.user ? state.user.email : '';
    },
    profileName: (state): string => {
      return state.user ? state.user.profile.name : '';
    },
    currentCompany: (state): LoggedUserInfoWorkspaceCompany | undefined => {
      return state.user?.workspace.company;
    },
    currentBranch: (state): LoggedUserInfoWorkspaceBranch | undefined => {
      return state.user?.workspace.branch;
    },
    companies: (state): AuthStoreCompanies[] => {
      return state.user
        ? state.user.profile.acceses.map(access => ({
            name: access.name,
            branch: access.branches[0].name,
            active: access.id === state.user?.workspace.company.id,
            companyId: access.id,
            branchId: access.branches[0].id,
            branches: access.branches
          }))
        : [];
    },
    companyDeclareTaxes: (state): boolean => {
      const companyId = state.user?.workspace.company.companyType?.id;
      const naturalTypeId = state.user?.workspace.company.naturalType?.id;

      return companyId === 1 || (companyId === 2 && naturalTypeId === 2);
    },
    modules: (state): { id: string; name: string }[] => {
      const companyId = state.user?.workspace.company.id;
      const branchId = state.user?.workspace.branch.id;

      return state.user
        ? state.user.profile.acceses
            .find(a => a.id === companyId)
            ?.branches.find(b => b.id === branchId)
            ?.modules.sort((a, b) => {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            }) || []
        : [];
    },
    hasElectronicInvoicing: ({ user }): boolean => {
      return user?.workspace.company.enableElectronicInvoicing || false;
    },
    isElectronicInvoicingActive: ({ user }): boolean => {
      return !(user?.workspace.company.enableElectronicInvoicing || false)
        ? false
        : user?.workspace.company.invoicesElectronicSv?.isActive || false;
    },
    getSetting:
      state =>
      (key: SystemSettingKeys): number => {
        return state.user?.workspace.company.settings[key] || 2;
      }
  }
});
