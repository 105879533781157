// plugins/sentry-user-context.js
import { useAuthStore } from '~/stores/AuthStore';
import * as Sentry from '@sentry/nuxt';
import type { LoggedUserInfo } from '@openbox/shared-types/lib';

export default defineNuxtPlugin(() => {
  const authStore = useAuthStore();

  // Set initial user context if user is already logged in
  if (authStore.user) {
    setUserContext(authStore.user);
  }

  // Watch for changes in user data
  watch(
    () => authStore.user,
    newUser => {
      setUserContext(newUser);
    },
    { immediate: true }
  );

  function setUserContext(user: LoggedUserInfo | null) {
    Sentry.setUser({
      id: user?.id,
      name: user ? `${user?.names} ${user?.lastnames}` : null,
      email: user?.email,
      profileId: user?.profile?.id
    });

    Sentry.setContext('company', {
      id: authStore.currentCompany?.id,
      name: authStore.currentCompany?.name
    });

    Sentry.setContext('branch', {
      id: authStore.currentBranch?.id,
      name: authStore.currentBranch?.name
    });
  }
});
