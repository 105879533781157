import revive_payload_client_hKlV6xLlYX from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_encoding@0.1.13_eslint@9.9.1_jiti@_39f6de32598236f1c3008d01c37c83ed/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Qfs6ACYnhs from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_encoding@0.1.13_eslint@9.9.1_jiti@_39f6de32598236f1c3008d01c37c83ed/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_SYjYCMIGMH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_encoding@0.1.13_eslint@9.9.1_jiti@_39f6de32598236f1c3008d01c37c83ed/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_By8HoBfE6A from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_encoding@0.1.13_eslint@9.9.1_jiti@_39f6de32598236f1c3008d01c37c83ed/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_OLt7KLELDS from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_encoding@0.1.13_eslint@9.9.1_jiti@_39f6de32598236f1c3008d01c37c83ed/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zFAqFuhmp2 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_encoding@0.1.13_eslint@9.9.1_jiti@_39f6de32598236f1c3008d01c37c83ed/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_csnKFvGENr from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.24.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5jPg3JAHU1 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_encoding@0.1.13_eslint@9.9.1_jiti@_39f6de32598236f1c3008d01c37c83ed/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_YN2IJ3QqFb from "/opt/build/repo/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+_64193d5f10130bbf354dfda5e5fa38d4/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import plugin_vvigc7JwFc from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.5.2_magicast@0.3.5_rollup@4.24.0_vite@5.4.2_@types+node@22.5.2_sass@1.77.8_b02080473af26fcf7fee270df8ebe94e/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_gXW5D7Pji1 from "/opt/build/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.24.0__6818f0bc316d88fa558b5166c86404e5/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import sentry_3AyO8nEfhE from "/opt/build/repo/plugins/sentry.ts";
import session_qoMCkkKHp8 from "/opt/build/repo/plugins/session.ts";
export default [
  revive_payload_client_hKlV6xLlYX,
  unhead_Qfs6ACYnhs,
  router_SYjYCMIGMH,
  navigation_repaint_client_By8HoBfE6A,
  check_outdated_build_client_OLt7KLELDS,
  chunk_reload_client_zFAqFuhmp2,
  plugin_vue3_csnKFvGENr,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5jPg3JAHU1,
  sentry_client_YN2IJ3QqFb,
  sentry_client_config_o34nk2sJbg,
  plugin_vvigc7JwFc,
  plugin_gXW5D7Pji1,
  sentry_3AyO8nEfhE,
  session_qoMCkkKHp8
]