import type {
  AuthAuthGetSingleUserResponse,
  AuthAuthProcessLoginRequest,
  AuthAuthProcessLoginResponse,
  AuthAuthProcessRefreshResponse,
  JWTResponse
} from '@openbox/shared-types/lib';
import { Buffer } from 'buffer';
import { useAuthStore } from '../stores/AuthStore';
import { useLocalStorage } from '@vueuse/core';

/**
 * Variable global para llevar el estado de la promesa de refresco.
 * Si es null, no hay refresco en progreso.
 * Si tiene una promesa, hay un refresco en curso.
 */
let refreshInProgress: Promise<void> | null = null;

type UseAuthSignInResponse = {
  success: boolean;
  message: string;
};

type Token = {
  exp: number;
  iat: number;
  rmb: boolean;
};

export default function useAuth() {
  const runtimeConfig = useRuntimeConfig();

  // Decodifica un JWT para extraer su payload (exp, iat, etc.)
  const decodeToken = (token: string): Token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(Buffer.from(base64, 'base64').toString('utf-8')) as Token;
  };

  // Guarda tokens en LocalStorage
  const setLocalStorage = ({ access_token, refresh_token }: JWTResponse): void => {
    const { exp, rmb } = decodeToken(access_token);
    localStorage.setItem('auth.refresh_token', refresh_token);
    localStorage.setItem('auth.access_token.expiration', exp.toString());
    localStorage.setItem('auth.access_token.remember', rmb.toString());
  };

  // Limpia LocalStorage (tokens, etc.)
  const clearLocalStorage = (): void => {
    localStorage.removeItem('auth.refresh_token');
    localStorage.removeItem('auth.access_token.expiration');
    localStorage.removeItem('auth.access_token.remember');
  };

  /**
   * Inicia sesión: llama a /auth/login y, si es exitoso, guarda tokens y obtiene datos del usuario.
   */
  const signIn = async (login: AuthAuthProcessLoginRequest): Promise<UseAuthSignInResponse> => {
    const { data, error } = await useFetch<AuthAuthProcessLoginResponse>('/auth/login', {
      baseURL: runtimeConfig.public.API_URL,
      method: 'POST',
      credentials: 'include',
      body: login
    });

    let success = true;
    let message = 'Iniciando sesión...';

    if (error.value) {
      return {
        success: false,
        message: error.value.data?.message || 'An error occurred.'
      };
    }

    if (data.value) {
      // Una vez logueado, obtenemos los datos del usuario
      const getUserResponse = await getUser();

      if (!getUserResponse.success) {
        success = getUserResponse.success;
        message = getUserResponse.message;
      } else {
        // Guarda los tokens en LocalStorage
        setLocalStorage(data.value.data);
      }
    }

    return { success, message };
  };

  /**
   * Cierra sesión: llama a /auth/logout y limpia el estado local.
   */
  const signOut = async (): Promise<void> => {
    const store = useAuthStore();
    const { data } = await useAuthenticatedFetch<AuthAuthProcessLoginResponse>('/auth/logout', {
      baseURL: runtimeConfig.public.API_URL,
      method: 'post',
      credentials: 'include'
    });

    if (data.value) {
      store.logOut();
      clearLocalStorage();
      navigateTo('/auth/login');
    }
  };

  /**
   * Obtiene los datos del usuario actual (GET /auth/user)
   */
  const getUser = async (): Promise<UseAuthSignInResponse> => {
    const store = useAuthStore();
    const { data, error } = await useFetch<AuthAuthGetSingleUserResponse>('/auth/user', {
      baseURL: runtimeConfig.public.API_URL,
      method: 'GET',
      credentials: 'include',
      async onRequest() {
        if (shouldRefreshAccessToken()) {
          await getRefreshPromise();
        }
      },
      onResponse({ response }) {
        if (response._data?.statusCode === 401) {
          signOut();
          reloadNuxtApp();
        }
      }
    });

    let success = true;
    let message = '';

    if (data.value) {
      store.setUser(data.value.data);
    }

    if (error.value) {
      success = false;
      message = error.value.data.message;
    }

    return { success, message };
  };

  /**
   * Determina si el token está por expirar (dentro del bufferTime) o ya expiró.
   */
  const shouldRefreshAccessToken = (): boolean => {
    const expiration = localStorage.getItem('auth.access_token.expiration');

    if (!expiration) {
      // Si no hay expiration, forzamos refresh
      return true;
    }

    const expirationTime = Number(expiration) * 1000; // Convertir a ms
    const currentTime = Date.now();
    const bufferTime = 55 * 1000; // 55s antes de expirar

    // True si ya estamos dentro de la ventana de 55s antes de la expiración
    return currentTime >= expirationTime - bufferTime;
  };

  /**
   * Llama al endpoint de refresco y actualiza los tokens si todo va bien.
   */
  const refreshToken = async (): Promise<void> => {
    const store = useAuthStore();
    const refreshToken = localStorage.getItem('auth.refresh_token');

    if (refreshToken) {
      const { data, error } = await useFetch<AuthAuthProcessRefreshResponse>('/auth/refresh-token', {
        baseURL: runtimeConfig.public.API_URL,
        method: 'POST',
        credentials: 'include',
        body: { refreshToken }
      });

      if (data.value) {
        setLocalStorage(data.value.data);
        await getUser();
      }

      if (error.value) {
        console.log('Error refreshing token:', error.value);
        store.logOut();
        clearLocalStorage();
        navigateTo('/auth/login');
      }
    } else {
      store.logOut();
      clearLocalStorage();
      navigateTo('/auth/login');
    }
  };

  /**
   * Envuelve refreshToken en una promesa única para evitar múltiples llamadas simultáneas.
   */
  async function getRefreshPromise(): Promise<void> {
    // Si NO hay un refresh en curso, lo iniciamos
    if (!refreshInProgress) {
      refreshInProgress = refreshToken().finally(() => {
        refreshInProgress = null; // Cuando termine, limpiamos la variable
      });
    }
    // Retornamos la promesa, sea la recién creada o la que ya existía
    return refreshInProgress;
  }

  /**
   * Indica si el usuario está logueado (verificando store o expiración).
   */
  const isLoggedIn = (): boolean => {
    const store = useAuthStore();
    const expiration = localStorage.getItem('auth.access_token.expiration');
    return store.isLoggedIn || expiration !== null;
  };

  return {
    signIn,
    signOut,
    shouldRefreshAccessToken,
    getUser,
    setLocalStorage,
    clearLocalStorage,
    refreshToken,
    getRefreshPromise, // Exportamos la función que maneja la promesa de refresco
    isLoggedIn
  };
}
