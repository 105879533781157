import { default as loginMdibZuifKQMeta } from "/opt/build/repo/pages/auth/login.vue?macro=true";
import { default as editZuHKn9DT16Meta } from "/opt/build/repo/pages/customers/edit.vue?macro=true";
import { default as indexuqTjbSei46Meta } from "/opt/build/repo/pages/customers/index.vue?macro=true";
import { default as newEgSW6TkXkTMeta } from "/opt/build/repo/pages/customers/new.vue?macro=true";
import { default as reports72TWwleTdIMeta } from "/opt/build/repo/pages/customers/reports.vue?macro=true";
import { default as settingst6C4RpgHfxMeta } from "/opt/build/repo/pages/customers/settings.vue?macro=true";
import { default as edit2kUFORgIb0Meta } from "/opt/build/repo/pages/entries/edit.vue?macro=true";
import { default as indexUyVygpY25WMeta } from "/opt/build/repo/pages/entries/index.vue?macro=true";
import { default as newsHOvUe59OTMeta } from "/opt/build/repo/pages/entries/new.vue?macro=true";
import { default as reportsUyasmqKdpYMeta } from "/opt/build/repo/pages/entries/reports.vue?macro=true";
import { default as settingsEE9kkhxjU1Meta } from "/opt/build/repo/pages/entries/settings.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as editSMwUkfQfGNMeta } from "/opt/build/repo/pages/inventories/edit.vue?macro=true";
import { default as indexMAViDNG4cSMeta } from "/opt/build/repo/pages/inventories/index.vue?macro=true";
import { default as newhIkg65meweMeta } from "/opt/build/repo/pages/inventories/new.vue?macro=true";
import { default as editAZU7byNJrXMeta } from "/opt/build/repo/pages/inventories/products/edit.vue?macro=true";
import { default as indexZiFfwOp0evMeta } from "/opt/build/repo/pages/inventories/products/index.vue?macro=true";
import { default as newZbOp2OEsU4Meta } from "/opt/build/repo/pages/inventories/products/new.vue?macro=true";
import { default as reportsw2NBvMRwlPMeta } from "/opt/build/repo/pages/inventories/reports.vue?macro=true";
import { default as settingsk07UZEaHahMeta } from "/opt/build/repo/pages/inventories/settings.vue?macro=true";
import { default as editCado7lUQw4Meta } from "/opt/build/repo/pages/legal/advisories/edit.vue?macro=true";
import { default as index5dqN90DSgrMeta } from "/opt/build/repo/pages/legal/advisories/index.vue?macro=true";
import { default as newKc2YWvuCAGMeta } from "/opt/build/repo/pages/legal/advisories/new.vue?macro=true";
import { default as editLZYClfnTROMeta } from "/opt/build/repo/pages/legal/edit.vue?macro=true";
import { default as indexlWyU3b38IQMeta } from "/opt/build/repo/pages/legal/index.vue?macro=true";
import { default as newhr73OCcbrXMeta } from "/opt/build/repo/pages/legal/new.vue?macro=true";
import { default as reportsEJx5w0CH1CMeta } from "/opt/build/repo/pages/legal/reports.vue?macro=true";
import { default as settingsbMjt60kK1JMeta } from "/opt/build/repo/pages/legal/settings.vue?macro=true";
import { default as indexdqpWvMS4ESMeta } from "/opt/build/repo/pages/management/index.vue?macro=true";
import { default as indexQoRDJaUPC4Meta } from "/opt/build/repo/pages/notifications/index.vue?macro=true";
import { default as profileNJNbyWXx9rMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as editz9Qh3ZqocsMeta } from "/opt/build/repo/pages/providers/edit.vue?macro=true";
import { default as indexniduXlnLmkMeta } from "/opt/build/repo/pages/providers/index.vue?macro=true";
import { default as newWxhOJ5LDl6Meta } from "/opt/build/repo/pages/providers/new.vue?macro=true";
import { default as reportspnRGNE4FWCMeta } from "/opt/build/repo/pages/providers/reports.vue?macro=true";
import { default as settings7tJpXMcooNMeta } from "/opt/build/repo/pages/providers/settings.vue?macro=true";
import { default as edit1usw77mrx8Meta } from "/opt/build/repo/pages/sales/edit.vue?macro=true";
import { default as indexYSKvFEvfxxMeta } from "/opt/build/repo/pages/sales/index.vue?macro=true";
import { default as newdfLyPHrpgUMeta } from "/opt/build/repo/pages/sales/new.vue?macro=true";
import { default as reportsr8It8gtoCuMeta } from "/opt/build/repo/pages/sales/reports.vue?macro=true";
import { default as reserveKuiRXY9wtlMeta } from "/opt/build/repo/pages/sales/reserve.vue?macro=true";
import { default as settingslQVmA9EkRhMeta } from "/opt/build/repo/pages/sales/settings.vue?macro=true";
import { default as editQLI8AV87CPMeta } from "/opt/build/repo/pages/services/edit.vue?macro=true";
import { default as indexi5MzpDfjPNMeta } from "/opt/build/repo/pages/services/index.vue?macro=true";
import { default as new08McTkn141Meta } from "/opt/build/repo/pages/services/new.vue?macro=true";
import { default as reportsxqgV8vntyXMeta } from "/opt/build/repo/pages/services/reports.vue?macro=true";
import { default as settings54oJAjOTCfMeta } from "/opt/build/repo/pages/services/settings.vue?macro=true";
import { default as edithw6DsiYImfMeta } from "/opt/build/repo/pages/taxes/edit.vue?macro=true";
import { default as indexIMJzEy9C52Meta } from "/opt/build/repo/pages/taxes/index.vue?macro=true";
import { default as newB0EZST51nUMeta } from "/opt/build/repo/pages/taxes/new.vue?macro=true";
import { default as reportsJw07NMA20oMeta } from "/opt/build/repo/pages/taxes/reports.vue?macro=true";
import { default as settings8qsUg6vKmhMeta } from "/opt/build/repo/pages/taxes/settings.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginMdibZuifKQMeta || {},
    component: () => import("/opt/build/repo/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "customers-edit",
    path: "/customers/edit",
    component: () => import("/opt/build/repo/pages/customers/edit.vue").then(m => m.default || m)
  },
  {
    name: "customers",
    path: "/customers",
    component: () => import("/opt/build/repo/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-new",
    path: "/customers/new",
    component: () => import("/opt/build/repo/pages/customers/new.vue").then(m => m.default || m)
  },
  {
    name: "customers-reports",
    path: "/customers/reports",
    component: () => import("/opt/build/repo/pages/customers/reports.vue").then(m => m.default || m)
  },
  {
    name: "customers-settings",
    path: "/customers/settings",
    component: () => import("/opt/build/repo/pages/customers/settings.vue").then(m => m.default || m)
  },
  {
    name: "entries-edit",
    path: "/entries/edit",
    component: () => import("/opt/build/repo/pages/entries/edit.vue").then(m => m.default || m)
  },
  {
    name: "entries",
    path: "/entries",
    component: () => import("/opt/build/repo/pages/entries/index.vue").then(m => m.default || m)
  },
  {
    name: "entries-new",
    path: "/entries/new",
    component: () => import("/opt/build/repo/pages/entries/new.vue").then(m => m.default || m)
  },
  {
    name: "entries-reports",
    path: "/entries/reports",
    component: () => import("/opt/build/repo/pages/entries/reports.vue").then(m => m.default || m)
  },
  {
    name: "entries-settings",
    path: "/entries/settings",
    component: () => import("/opt/build/repo/pages/entries/settings.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inventories-edit",
    path: "/inventories/edit",
    component: () => import("/opt/build/repo/pages/inventories/edit.vue").then(m => m.default || m)
  },
  {
    name: "inventories",
    path: "/inventories",
    component: () => import("/opt/build/repo/pages/inventories/index.vue").then(m => m.default || m)
  },
  {
    name: "inventories-new",
    path: "/inventories/new",
    component: () => import("/opt/build/repo/pages/inventories/new.vue").then(m => m.default || m)
  },
  {
    name: "inventories-products-edit",
    path: "/inventories/products/edit",
    component: () => import("/opt/build/repo/pages/inventories/products/edit.vue").then(m => m.default || m)
  },
  {
    name: "inventories-products",
    path: "/inventories/products",
    component: () => import("/opt/build/repo/pages/inventories/products/index.vue").then(m => m.default || m)
  },
  {
    name: "inventories-products-new",
    path: "/inventories/products/new",
    component: () => import("/opt/build/repo/pages/inventories/products/new.vue").then(m => m.default || m)
  },
  {
    name: "inventories-reports",
    path: "/inventories/reports",
    component: () => import("/opt/build/repo/pages/inventories/reports.vue").then(m => m.default || m)
  },
  {
    name: "inventories-settings",
    path: "/inventories/settings",
    component: () => import("/opt/build/repo/pages/inventories/settings.vue").then(m => m.default || m)
  },
  {
    name: "legal-advisories-edit",
    path: "/legal/advisories/edit",
    component: () => import("/opt/build/repo/pages/legal/advisories/edit.vue").then(m => m.default || m)
  },
  {
    name: "legal-advisories",
    path: "/legal/advisories",
    component: () => import("/opt/build/repo/pages/legal/advisories/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-advisories-new",
    path: "/legal/advisories/new",
    component: () => import("/opt/build/repo/pages/legal/advisories/new.vue").then(m => m.default || m)
  },
  {
    name: "legal-edit",
    path: "/legal/edit",
    component: () => import("/opt/build/repo/pages/legal/edit.vue").then(m => m.default || m)
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/opt/build/repo/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-new",
    path: "/legal/new",
    component: () => import("/opt/build/repo/pages/legal/new.vue").then(m => m.default || m)
  },
  {
    name: "legal-reports",
    path: "/legal/reports",
    component: () => import("/opt/build/repo/pages/legal/reports.vue").then(m => m.default || m)
  },
  {
    name: "legal-settings",
    path: "/legal/settings",
    component: () => import("/opt/build/repo/pages/legal/settings.vue").then(m => m.default || m)
  },
  {
    name: "management",
    path: "/management",
    component: () => import("/opt/build/repo/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/opt/build/repo/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "providers-edit",
    path: "/providers/edit",
    component: () => import("/opt/build/repo/pages/providers/edit.vue").then(m => m.default || m)
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/opt/build/repo/pages/providers/index.vue").then(m => m.default || m)
  },
  {
    name: "providers-new",
    path: "/providers/new",
    component: () => import("/opt/build/repo/pages/providers/new.vue").then(m => m.default || m)
  },
  {
    name: "providers-reports",
    path: "/providers/reports",
    component: () => import("/opt/build/repo/pages/providers/reports.vue").then(m => m.default || m)
  },
  {
    name: "providers-settings",
    path: "/providers/settings",
    component: () => import("/opt/build/repo/pages/providers/settings.vue").then(m => m.default || m)
  },
  {
    name: "sales-edit",
    path: "/sales/edit",
    component: () => import("/opt/build/repo/pages/sales/edit.vue").then(m => m.default || m)
  },
  {
    name: "sales",
    path: "/sales",
    component: () => import("/opt/build/repo/pages/sales/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-new",
    path: "/sales/new",
    component: () => import("/opt/build/repo/pages/sales/new.vue").then(m => m.default || m)
  },
  {
    name: "sales-reports",
    path: "/sales/reports",
    component: () => import("/opt/build/repo/pages/sales/reports.vue").then(m => m.default || m)
  },
  {
    name: "sales-reserve",
    path: "/sales/reserve",
    component: () => import("/opt/build/repo/pages/sales/reserve.vue").then(m => m.default || m)
  },
  {
    name: "sales-settings",
    path: "/sales/settings",
    component: () => import("/opt/build/repo/pages/sales/settings.vue").then(m => m.default || m)
  },
  {
    name: "services-edit",
    path: "/services/edit",
    component: () => import("/opt/build/repo/pages/services/edit.vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/opt/build/repo/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "services-new",
    path: "/services/new",
    component: () => import("/opt/build/repo/pages/services/new.vue").then(m => m.default || m)
  },
  {
    name: "services-reports",
    path: "/services/reports",
    component: () => import("/opt/build/repo/pages/services/reports.vue").then(m => m.default || m)
  },
  {
    name: "services-settings",
    path: "/services/settings",
    component: () => import("/opt/build/repo/pages/services/settings.vue").then(m => m.default || m)
  },
  {
    name: "taxes-edit",
    path: "/taxes/edit",
    component: () => import("/opt/build/repo/pages/taxes/edit.vue").then(m => m.default || m)
  },
  {
    name: "taxes",
    path: "/taxes",
    component: () => import("/opt/build/repo/pages/taxes/index.vue").then(m => m.default || m)
  },
  {
    name: "taxes-new",
    path: "/taxes/new",
    component: () => import("/opt/build/repo/pages/taxes/new.vue").then(m => m.default || m)
  },
  {
    name: "taxes-reports",
    path: "/taxes/reports",
    component: () => import("/opt/build/repo/pages/taxes/reports.vue").then(m => m.default || m)
  },
  {
    name: "taxes-settings",
    path: "/taxes/settings",
    component: () => import("/opt/build/repo/pages/taxes/settings.vue").then(m => m.default || m)
  }
]