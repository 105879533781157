import type { AvailableRouterMethod, NitroFetchRequest } from 'nitropack';
import { useRuntimeConfig } from 'nuxt/app';
import type { FetchResult, UseFetchOptions } from 'nuxt/app.js';
import type { AsyncData, KeysOf, PickFrom } from 'nuxt/dist/app/composables/asyncData';
import type { FetchError, FetchOptions, FetchResponse } from 'ofetch';
import type { Ref } from 'vue';
import { unref } from 'vue';

export const useAuthenticatedFetch = <
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void
    ? 'get' extends AvailableRouterMethod<ReqT>
      ? 'get'
      : AvailableRouterMethod<ReqT>
    : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
  opts?: UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>
): AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | null> => {
  const store = useAuthStore();
  const { shouldRefreshAccessToken, getRefreshPromise, clearLocalStorage } = useAuth();
  const runtimeConfig = useRuntimeConfig();

  // Helper function to normalize headers
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function normalizeHeaders(headers: any): HeadersInit {
    if (Array.isArray(headers)) {
      return headers.map(([key, value]) => [unref(key), unref(value)]);
    } else if (headers instanceof Headers) {
      return headers;
    } else {
      const normalizedHeaders: Record<string, string> = {};
      for (const key in headers) {
        normalizedHeaders[key] = unref(headers[key]);
      }
      return normalizedHeaders;
    }
  }

  const api = $fetch.create({
    baseURL: runtimeConfig.public.API_URL,
    credentials: 'include',
    headers: opts?.headers ? normalizeHeaders(opts.headers) : undefined,
    async onRequest() {
      if (shouldRefreshAccessToken()) {
        await getRefreshPromise();
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onResponse({ response }: { response: FetchResponse<any> }) {
      if (response._data?.statusCode === 401) {
        store.logOut();
        clearLocalStorage();
        navigateTo('/auth/login');
      }
      const { finish } = useLoadingIndicator();
      finish();
    }
  });

  return useFetch(request, {
    ...opts,
    $fetch: api
  });
};
